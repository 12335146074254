<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Correct Container Number</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-breadcrumbs :items="$store.state.routes" divider=" | "></v-breadcrumbs>
                <v-row>
                    <v-col cols="3">
                        <v-text-field
                            v-model="wrong_container_number"
                            prepend-inner-icon="mdi-train-car-container"
                            label="Wrong Container Number..."
                            placeholder="Wrong Container Number..."
                            outlined
                            dense
                            @keyup="getWrongContainerNumbers()"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="1" class="d-flex align-center justify-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon 
                                    size="40" 
                                    color="green" 
                                    class="mb-5" 
                                    :disabled="!matched || !matched2" 
                                    v-on="on"
                                    @click="correctContainerNumber()"
                                >mdi-arrow-right-box</v-icon>
                            </template>
                            <span>Correct Container Number!</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field
                            v-model="correct_container_number"
                            prepend-inner-icon="mdi-train-car-container"
                            label="Correct Container Number..."
                            placeholder="Correct Container Number..."
                            outlined
                            dense
                            :disabled="!matched"
                            @keyup="checkCorrectContainerNumber()"
                        ></v-text-field>                    
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h3>[transactions]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    transaction_id
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.transactions"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.transaction_id }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                    <v-col>
                        <h3>[surveys]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    survey_id
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.surveys"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.survey_id }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h3>[pictures]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    file_name
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.pictures"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.file_name }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                    <v-col>
                        <h3>[receipts]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    eir_no
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>                                
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.receipts"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.eir_no }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h3>[job_orders]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    job_order_id
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.job_orders"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.job_order_id }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                    <v-col>
                        <h3>[damages]</h3>
                        <v-simple-table dark dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    container_number
                                </th>
                                <th class="text-left">
                                    damage_id
                                </th>
                                <th class="text-left">
                                    created_at
                                </th>
                                <th class="text-left">
                                    updated_at
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in items.damages"
                                >
                                    <td>{{ item.container_number }}</td>
                                    <td>{{ item.damage_id }}</td>
                                    <td>{{ item.created_at | formatDate }}</td>
                                    <td>{{ item.updated_at | formatDate }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>                          
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            wrong_container_number: '',
            correct_container_number: '',
            items: [],
            matched: false,
            matched2: false,
        }
    },
    mounted() {
        if( !this.$store.state.user.role.match(/^su$|admin/) ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }

        if( !this.$store.state.routes.some(obj => obj.href == this.$route.path) ) {
            this.$store.commit(
                'addRoute',
                {
                    text: this.$route.path,
                    disabled: false,
                    href: this.$route.path,                
                }            
            );
        }         
    },
    methods: {
        getWrongContainerNumbers: function() {
            this.wrong_container_number = this.wrong_container_number.toUpperCase();
            if( this.wrong_container_number.match(/[^A-Z0-9]/g) ) {
                this.wrong_container_number = this.wrong_container_number.replace(/[^A-Z0-9]/g, "");
            }            
            if( !this.wrong_container_number.match(/^[A-Z]{4}[0-9]{7}$/)) {
                this.matched = false;
                return false;
            }
            this.matched = true;
            this.overlay = true;
            axios.post(
                '/api/get_wrong_container_number_info',
                {
                    wrong_container_number: this.wrong_container_number,
                }
            ).then(response => {
                this.items = response.data;
                // console.log(response.data);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        checkCorrectContainerNumber: function() {
            this.correct_container_number = this.correct_container_number.toUpperCase();
            if( this.correct_container_number.match(/[^A-Z0-9]/g) ) {
                this.correct_container_number = this.correct_container_number.replace(/[^A-Z0-9]/g, "");
            }    

            if( !this.correct_container_number.match(/^[A-Z]{4}[0-9]{7}$/)) {
                this.matched2 = false;
                return false;
            } else {
                this.matched2 = true;
            }
        },
        correctContainerNumber: function() {
            if( !confirm('Is it ok to correct the container number?') ) {
                return false;
            }
            this.overlay = true;
            axios.post(
                '/api/correct_container_number',
                {
                    transaction_id: this.items.transactions[0].transaction_id,
                    wrong_container_number: this.wrong_container_number,
                    correct_container_number: this.correct_container_number
                }
            ).then(response => {
                // console.log(response.data);
                if( response.status == 200 ) {
                    this.items = response.data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    }
}
</script>