<template>
    <v-container>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Services</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-breadcrumbs :items="$store.state.routes" divider=" | "></v-breadcrumbs>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :search="search"
                    :items-per-page="-1"
                >
                    <template v-slot:top>
                        <v-btn
                            color="red"
                            dark
                            block
                            class="lighten-3"
                            @click="openEditServiceDialogForInsert()"
                        >
                            <v-icon>mdi-plus</v-icon>
                            Insert New Service
                        </v-btn>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                            class="my-2"
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                                <v-tab
                                    v-for="(tabi, i) in tabIndications"
                                    :key="i"
                                >
                                    {{tabi}}
                                </v-tab>
                        </v-tabs>
                        <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-magnify"
                        label="Search by text..."
                        v-model="search"
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.actions`] = "{item}">
                        <v-icon
                            color="orange"
                            @click="openEditServiceDialogForUpdate(item)"
                            v-if="tab == 0"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            color="red"
                            @click="deleteService(item)"
                            v-if="tab == 0"
                        >
                            mdi-delete
                        </v-icon>
                        <v-icon
                            color="green"
                            @click="revertService(item)"
                            v-if="tab == 1"
                        >
                            mdi-file-undo
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <!--editServiceDialog start-->
        <v-dialog
            v-model="editServiceDialog"
            fullscreen
        >
            <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            {{ editServiceDialogTitle }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="editServiceDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-combobox
                                    outlined
                                    dense
                                    label="ComponentCode"
                                    v-model="component_code"
                                    :items="componentCodes"
                                    clearable
                                    :rules="rules"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="4">
                                <v-combobox
                                    outlined
                                    dense
                                    label="PartName"
                                    v-model="part_name"
                                    :items="partNames"
                                    clearable
                                    :rules="rules"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="4">
                                <v-combobox
                                    outlined
                                    dense
                                    label="Description"
                                    v-model="description"
                                    clearable
                                    :items="descriptions"
                                    :rules="rules"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-combobox
                                    outlined
                                    dense
                                    label="Location"
                                    v-model="location"
                                    clearable
                                    :items="locations"
                                    :rules="rules"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="4">
                                <v-combobox
                                    outlined
                                    dense
                                    label="WayOfRepair"
                                    v-model="way_of_repair"
                                    clearable
                                    :items="wayOfRepairs"
                                    :rules="rules"
                                ></v-combobox>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="Hour"
                                    type="number"
                                    v-model="hour"
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="MaterialCost"
                                    type="number"
                                    v-model="material_cost"
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    outlined
                                    dense
                                    label="LaborCost"
                                    type="number"
                                    v-model="labor_cost"
                                    :rules="rules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-switch
                                    v-model="is_active"
                                    label="Active"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            color="red"
                            class="lighten-3"
                            block
                            dark
                            @click="addService()"
                            v-if="editServiceDialogTitle == 'Add New Service'"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            Add Service
                        </v-btn>
                        <v-btn
                            color="orange"
                            class="lighten-3"
                            block
                            dark
                            @click="updateService()"
                            v-if="editServiceDialogTitle == 'Update Service'"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            Update Service
                        </v-btn>
                    </v-card-actions>
                    </v-form>
                </v-container>
            </v-card>
        </v-dialog>
        <!--editServiceDialog end-->
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            headers: [
                {
                    text: "Id",
                    value: "id",
                    sortable: true,
                },
                {
                    text: "ComponentCode",
                    value: "component_code",
                    sortable: true,
                },
                {
                    text: "ServiceId",
                    value: "service_id",
                    sortable: true,
                },
                {
                    text: "PartName",
                    value: "part_name",
                    sortable: true,
                },
                {
                    text: "Description",
                    value: "description",
                    sortable: true,
                },
                {
                    text: "Location",
                    value: "location",
                    sortable: true,
                },
                {
                    text: "Way of Repair",
                    value: "way_of_repair",
                    sortable: true,
                },
                {
                    text: "Hour",
                    value: "hour",
                    sortable: true,
                },
                {
                    text: "MaterialCost",
                    value: "material_cost",
                    sortable: true,
                },
                {
                    text: "LaborCost",
                    value: "labor_cost",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                }
            ],
            items: [],
            search: null,
            tab: 0,
            tabIndications: [
                'Active',
                'Inactive'
            ],
            editServiceDialog: false,
            editServiceDialogTitle: null,
            id: null,
            component_code: null,
            part_name: null,
            description: null,
            location: null,
            way_of_repair: null,
            hour: null,
            material_cost: null,
            labor_cost: null,
            is_active: true,
            rules: [(v) => !!v || "Required!"],
            componentCodes: [],
            partNames: [],
            locations: [],
            wayOfRepairs: [],
            descriptions: [],       
        }
    },
    mounted() {
        if( !this.$store.state.user.role.match(/^su$|admin/) ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }

        this.getServices(this.tab);
        this.getComponentCodes();
        this.getLocationsList();
        this.getPartNames();
        this.getWayOfRepairsList();
        this.getDescriptionsList();
        
        if( !this.$store.state.routes.some(obj => obj.href == this.$route.path) ) {
            this.$store.commit(
                'addRoute',
                {
                    text: this.$route.path,
                    disabled: false,
                    href: this.$route.path,                
                }            
            );
        } 
    },
    methods: {
        getServices: function(tab) {
            this.overlay = true;
            this.loading = true;

            let isActive = 0;

            switch(tab) {
                case 0:
                    isActive = 1;
                    break;
                case 1:
                    isActive = 0;
                    break;
            }

            this.axios.post(
                '/api/get_services',
                {
                    isActive: isActive,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.items = response.data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getComponentCodes: function() {
            axios.post(
                '/api/get_component_codes'
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.componentCodes = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getPartNames: function() {
            axios.post(
                '/api/get_part_names'
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.partNames = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getLocationsList: function() {
            axios.post(
                '/api/get_locations_list'
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.locations = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getWayOfRepairsList: function() {
            axios.post(
                '/api/get_way_of_repairs_list'
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.wayOfRepairs = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getDescriptionsList: function() {
            axios.post(
                '/api/get_descriptions_list'
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.descriptions = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        openEditServiceDialogForUpdate: function(item) {
            this.editServiceDialog = true;
            this.id = item.id;
            this.component_code = item.component_code;
            this.part_name = item.part_name;
            this.description = item.description;
            this.location = item.location;
            this.way_of_repair = item.way_of_repair;
            this.hour = item.hour;
            this.material_cost = item.material_cost;
            this.labor_cost = item.labor_cost;
            this.is_active = item.is_active;
            this.editServiceDialogTitle = 'Update Service';
        },
        openEditServiceDialogForInsert: function() {
            this.editServiceDialog = true;
            this.editServiceDialogTitle = 'Add New Service';
            this.id = null;
            this.component_code = null;
            this.part_name = null;
            this.description = null;
            this.location = null;
            this.way_of_repair = null;
            this.hour = null;
            this.material_cost = null;
            this.labor_cost = null;
            this.is_active = true;
        },
        updateService: function() {
            if( !confirm('Is it ok to edit the record?') ) {
               return false; 
            }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_service',
                {
                    id: this.id,
                    component_code: this.component_code,
                    part_name: this.part_name,
                    description: this.description,
                    location: this.location,
                    way_of_repair: this.way_of_repair,
                    hour: this.hour,
                    material_cost: this.material_cost,
                    labor_cost: this.labor_cost,
                    is_active: this.is_active,
                }
            ).then(response => {
                // const index = this.items.indexOf(this.editServiceItem);
                if(response.status === 200) {
                    this.getServices(this.tab);
                    this.getComponentCodes();
                    this.getLocationsList();
                    this.getPartNames();
                    this.getWayOfRepairsList();
                    this.getDescriptionsList();                    
                    this.editServiceDialog = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deleteService: function(item) {
            if( !confirm('Is it ok to delete the service?') ) {
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/delete_service',
                {
                    id: item.id,
                }
            ).then(response => {
                console.log(response.data);
                if(response.status === 200) {
                    const index = this.items.indexOf(item);
                    this.items.splice(index, 1);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        revertService: function(item) {
            if( !confirm('Is it ok to revert the service?') ) {
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/revert_service',
                {
                    id: item.id,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const index = this.items.indexOf(item);
                    this.items.splice(index, 1);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        addService: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            if( !confirm('Is it ok to save the data?') ) {
                return false;
            }
            // console.log(this.editServiceItem)
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/add_service',
                {
                    id: this.id,
                    component_code: this.component_code,
                    part_name: this.part_name,
                    description: this.description,
                    location: this.location,
                    way_of_repair: this.way_of_repair,
                    hour: this.hour,
                    material_cost: this.material_cost,
                    labor_cost: this.labor_cost,
                    is_active: this.is_active,
                }
            ).then(response => {
                if( response.status == 200 ) {
                    this.getServices(this.tab);
                    this.getComponentCodes();
                    this.getLocationsList();
                    this.getPartNames();
                    this.getWayOfRepairsList();
                    this.getDescriptionsList();                    
                    this.editServiceDialog = false;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        }
    },
    watch: {
        tab: function() {
            this.getServices(this.tab);
        }
    }
}
</script>
