<template>
    <v-container>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Daily Movement Report</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-breadcrumbs :items="$store.state.routes" divider=" | "></v-breadcrumbs>
                <v-data-table
                    :items="txns"
                    :headers="txnHeaders"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-select
                            outlined
                            dense
                            v-model="shippingLine"
                            label="ShippingLine"
                            :items="companies"
                            item-text="name"
                            item-value="company_id"
                            prepend-icon="mdi-ferry"
                        ></v-select>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateRangeText"
                                    dense
                                    outlined
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <v-range-slider
                            v-model="timeRange"
                            :min="0"
                            :max="1440"
                            :step="1"
                            thumb-label="always"
                            :disabled="dates.length != 1"
                        >
                            <template v-slot:thumb-label="{ value }">
                            {{ formatTime(value) }}
                            </template>                
                        </v-range-slider>                        
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(destination, i) in destinations"
                                :key="i"
                            >
                                {{destination.destination_type}}({{ destination.destination_id }})
                            </v-tab>
                        </v-tabs>
                        <v-btn
                            block
                            dark
                            color="green"
                            @click="generateDailyMovementReportExcel()"
                            :disabled="txns.length == 0"
                            class="my-2"
                            v-if="tab == 0"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            Generate Report
                        </v-btn>
                        <v-btn
                            block
                            dark
                            color="green"
                            @click="generateDailyMovementReportExcelWithNewTemplate()"
                            :disabled="txns.length == 0"
                            class="my-2"
                            v-if="tab != 0"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            Generate Report
                        </v-btn>
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                        <v-pagination
                            v-model="page"
                            :length="pageCount"
                            total-visible="10"
                            dark
                            @input="changePage()"
                        ></v-pagination>
                    </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            shippingLine: 'CLI-0002',
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            companies: [],
            txnHeaders: [
                {
                    text: "GateIn",
                    value: "job_order.gate_in_at",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "Company",
                    value: "company.name",
                    sortable: false,
                },
                {
                    text: "ContainerSize",
                    value: "size.size",
                    sortable: false,
                },
                {
                    text: "ContainerType",
                    value: "type.type",
                    sortable: false,
                },
                {
                    text: "ContainerClass",
                    value: "class.class",
                    sortable: false,
                },
                {
                    text: "MfgDate",
                    value: "manufacturing_date",
                    sortable: false,
                },
            ],
            txns: [],
            destinations: [],
            tab: 0,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            timeRange: [0, 1440],
            fromTime: '00:00:00',
            untilTime: '24:00:00',
        }
    },
    mounted() {
        if( !this.$store.state.user.role.match(/^su$|admin/) ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }   
        this.getTransactionsForDailyMovementReport(this.tab);
        this.getCompanies();
        this.getDestinations();

        if( !this.$store.state.routes.some(obj => obj.href == this.$route.path) ) {
            this.$store.commit(
                'addRoute',
                {
                    text: this.$route.path,
                    disabled: false,
                    href: this.$route.path,                
                }            
            );
        }        
    },
    methods: {
        getDestinations: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post('/api/get_destinations')
                .then(response => {
                    this.destinations = response.data;
                    // console.log(this.destinations);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getCompanies: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_companies',
            ).then(response => {
                this.companies = response.data;
                // console.log(response.data);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getTransactionsForDailyMovementReport: function(tab) {
            console.log(this.fromTime, this.untilTime)
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_transactions_for_daily_movement_report',
                {
                    company_id: this.shippingLine,
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    tab: tab,
                }
            ).then(response => {
                // console.log(this.dates);
                this.txns = response.data.data;
                this.totalNumberOfRecords = response.data.total;
                this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                // console.log(this.txns)

                // const job_order_services = this.txns[0].job_order.job_order_services;
                // for( let i = 0; i < job_order_services.length; i++ ) {
                //     console.log(job_order_services[i].service.description);
                // }

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        generateDailyMovementReportExcel: function() {
            if( ! this.shippingLine ) {
                alert('Select any ShippingLine...');
                return false;
            }
            axios.post(
                '/api/generate_daily_movement_report_excel',
                {
                    company_id: this.shippingLine,
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,                    
                    tab: this.tab,
                }
            ).then(response => {
                // console.log(response.data['file'])
                // console.log(this.dates)
                // return false;
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'DailyMovementReport' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            })
        },
        generateDailyMovementReportExcelWithNewTemplate: function() {
            axios.post(
                '/api/generate_daily_movement_report_excel_with_new_template',
                {
                    company_id: this.shippingLine,
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,
                    tab: this.tab,
                }
            ).then(response => {
                // console.log(response.data['file'])
                // console.log(this.dates)
                // return false;
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'DailyMovementReport' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            })
        },
        changePage: function() {
            this.getTransactionsForDailyMovementReport(this.tab);
        },
        formatTime(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
        } ,        
    },
    watch: {
        shippingLine: function() {
            this.getTransactionsForDailyMovementReport(this.tab);
        },
        dates: function() {
            if (this.dates.length == 0) {
                return false;
            } else if (this.dates.length == 1) {
                return false;
            } else if (this.dates.length == 2) {
                this.timeRange = [0, 1440];
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                }
            }
            this.getTransactionsForDailyMovementReport(this.tab);
        },
        tab: function() {
            this.getTransactionsForDailyMovementReport(this.tab);
        },
        timeRange: function() {
            const fromHours = Math.floor(this.timeRange[0] / 60);
            const fromMinutes = this.timeRange[0] % 60;
            const untilHours = Math.floor(this.timeRange[1] / 60);
            const untilMinutes = this.timeRange[1] % 60;

            this.fromTime = String(fromHours).padStart(2, '0') + ':' + 
                            String(fromMinutes).padStart(2, '0') + ':00';
            this.untilTime = String(untilHours).padStart(2, '0') + ':' + 
                            String(untilMinutes).padStart(2, '0') + ':00';
            }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },
}
</script>
