<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container fluid>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title
                    >: {{ containerNumber }}</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text>
                    <v-data-table
                        :headers="damagesHeaders"
                        :items="damages"
                        :loading="loading"
                        :search="search"
                        :items-per-page="-1"
                    >
                        <template v-slot:top>
                            <v-btn
                                color="red"
                                class="lighten-2"
                                block
                                dark
                                @click="closeJob()"
                                v-if="canCloseJob"
                            >
                                <v-icon>mdi-stop-circle</v-icon>
                                Close Job
                            </v-btn>
                            <v-btn
                                color="green"
                                class="lighten-2 my-1"
                                block
                                dark
                                @click="goToTakePicture2()"
                            >
                                <v-icon>mdi-camera</v-icon>
                                Add Damage / Return To Survey
                            </v-btn>

                            <v-text-field
                                outlined
                                dense
                                prepend-inner-icon="mdi-magnify"
                                label="Search by text..."
                                v-model="search"
                            ></v-text-field>
                        </template>
                        <template v-slot:[`item.status_code`]="{ item }">
                            <v-chip
                                :color=statusColor(item.status_code)
                                dark
                            >{{ item.status_code }}</v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                color="orange"
                                dark
                                small
                                :to="`/encode_job_order_services/${transactionId}/${containerNumber}/${item.survey_id}/${item.job_order_services[0]?.job_order_id}/${item.damage_id}`"
                            >
                                <v-icon>mdi-toolbox</v-icon> encode
                            </v-btn>
                        </template>
                        </v-data-table>
                </v-card-text>                
            </v-container>
        </v-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            search: null,
            containerNumber: null,
            transactionId: null,
            surveyId: null,
            jobOrderId: null,
            tab: 0,
            damagesHeaders: [
                {
                    text: "PictureId",
                    value: "picture_id",
                    sortable: true,
                },
                {
                    text: "AreaCode",
                    value: "area_code",
                    sortable: true,
                },
                {
                    text: "LocationCode",
                    value: "location_code",
                    sortable: true,
                },
                {
                    text: "ToLocationCode",
                    value: "to_location_code",
                    sortable: true,
                },
                {
                    text: "DamageCode",
                    value: "damage_code",
                    sortable: true,
                },
                {
                    text: "StatusCode",
                    value: "status_code",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            damages: [],
            canCloseJob: false,
        }
    },
    mounted() {
        this.transactionId = this.$route.params.transaction_id;
        this.containerNumber = this.$route.params.container_number;
        this.surveyId = this.$route.params.survey_id;
        this.jobOrderId = this.$route.params.job_order_id;
        this.tab = this.$route.params.tab;
        this.getDamages();
    },
    methods: {
        closeJob: function() {
            if( !confirm('Is it ok to close the job?') ) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/close_job',
                {
                    user_id: this.$store.state.user.id,
                    transaction_id: this.transactionId,
                    job_order_id: this.jobOrderId,
                    container_location2: this.containerLocation2
                },
            ).then(response => {
                if(response.status == 200) {
                    window.location.href = '/survey/3';
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        statusColor: function(status) {
            switch(status) {
                case 'Not Yet Fixed':
                return 'red';
                break;
                case 'Ongoing':
                return 'blue';
                break;
                case 'Fixed':
                return 'green';
                break;
            }
        },
        getDamages: function() {
            this.axios.post(
                '/api/get_damages',
                {
                    transaction_id: this.transactionId,
                    container_number: this.containerNumber,
                    survey_id: this.surveyId,
                }
            ).then(response => {
                // console.log(response.data);
                this.damages = response.data;

                const unFixedDamages = this.damages.filter(function(value) {
                    return value.status_code != 'Fixed';
                });

                // console.log(this.damages)

                const postServicePictures = this.damages.filter(function(value) {
                    return value.picture.post_service_pictures.length > 0;
                });

                // console.log(postServicePictures);
                if( this.damages.length == 0 ) {
                    if(confirm('You need to encode damage(s) first! Go to the picture uploading page?')) {
                        // console.log(this.$route.params.container_number);
                        // console.log(this.$route.params.transaction_id);
                        this.$router.push('/take_picture2/' + this.$route.params.container_number + '/' + this.$route.params.transaction_id)
                    } else {
                        return false;
                    }



                }

                if( unFixedDamages.length == 0 && (postServicePictures.length >= this.damages.length ) && this.damages.length > 0 ) {
                    this.canCloseJob = true;
                } else {
                    this.canCloseJob = false;
                }

            }).catch(error => {
                console.log(error);
            });
        },
        getJobOrderId() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_id',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    survey_id: this.surveyId,
                },
            ).then(response => {
                this.jobOrderId = response.data.job_order_id;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        goToTakePicture2: function() {
            // console.log(this.$route.params.transaction_id);
            // console.log(this.$route.params.container_number);
            this.$router.push('/take_picture2/' + this.$route.params.container_number + '/' + this.$route.params.transaction_id);
        }
    }
}
</script>
