<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Transactions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-breadcrumbs :items="$store.state.routes" divider=" | "></v-breadcrumbs>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(remark, i) in remarks"
                                :key="i"
                            >
                            {{remark}}
                        </v-tab>
                        </v-tabs>
                        <v-tabs
                            v-model="jobOrderStatusTab"
                            center-active
                            dark
                            v-if="tab == 0"
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(status, i) in statuses"
                                :key="i"
                            >
                              {{ status.status_code }}
                            </v-tab>
                        </v-tabs>
                        <v-text-field
                            class="my-2"
                            label="Search by transaction_id or container_number..."
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>                            
                        <v-range-slider
                            v-model="timeRange"
                            :min="0"
                            :max="1440"
                            :step="1"
                            thumb-label="always"
                            :disabled="dates.length != 1"
                            >
                            <template v-slot:thumb-label="{ value }">
                                {{ formatTime(value) }}
                            </template>                
                        </v-range-slider>                        
                        <v-btn
                            block
                            dark
                            color="blue"
                            @click="openInventoryMovementReportDialog()"
                            class="my-2"
                            max-width="100%"
                        >
                            <v-icon>mdi-list-box</v-icon>
                            inventory movement report
                        </v-btn>                            
                        <!--Data Picker finish-->
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                    </template>
                    <template v-slot:[`item.container_located_at`]="{ item }">
                        <div v-if="item.container_located_at">
                            {{ item.container_located_at }}
                        </div>
                        <div v-else>
                            <v-btn
                                color="orange"
                                dark
                                small
                                v-if="item.job_order?.gate_in_at"
                                @click="openLocation1SelectionFormDialog(item)"
                            >
                                <v-icon>mdi-timetable</v-icon>
                                put timestamp
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.job_order_status`]="{ item }">
                        {{ jobOrderStatus(item) }}
                    </template>
                    <template v-slot:[`item.gate_in`]="{ item }">
                        <div v-if="item.job_order">
                            <div v-if="!item.job_order?.gate_in_at">
                                <v-btn
                                    color="orange"
                                    dark
                                    small
                                    @click="gateIn(item)"
                                    :disabled="item.status_history[0]?.status.status_code == 'REJECTED' || !item.remark_id"
                                >
                                    <v-icon>mdi-boom-gate-arrow-up</v-icon> Gate In
                                </v-btn>
                            </div>
                            <div v-else>
                                {{item.job_order?.gate_in_at}}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.pictures`]="{ item }">
                        <div v-if="item.remark_id">
                            <v-badge
                                color="red"
                                overlap
                                :content="item.pictures.length"
                            >
                            <v-icon
                                color="green"
                                size="35"
                                @click="openImagesDialog(item)"
                            >mdi-image</v-icon>
                            </v-badge>
                        </div>
                    </template>                    
                    <template v-slot:[`item.container_location2`]="{ item }">
                        <div v-if="!item.container_location2">
                            <v-btn
                                color="orange"
                                dark
                                small
                                @click="openLocation2SelectionFormDialog(item)"
                                :disabled="!item.job_order?.job_closed_at"
                            >
                            <v-icon>mdi-map-marker</v-icon> Set Location2
                        </v-btn>
                        </div>
                        <div v-else>
                            {{item.container_location2}}
                        </div>                        
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>

        <!-- inventoryMovementReportDialog start -->
        <v-dialog v-model="inventoryMovementReportDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            <div v-if="dates[0] !== dates[1] && dates[1]">
                                Inventory Movement Report - gate_in_at: {{ dates[0] + ' ~ ' + dates[1] }} -
                            </div>
                            <div v-else>
                                Inventory Movement Report - gate_in_at: {{ dates[0] }} - {{ formatTimeRange(timeRange) }}
                            </div>                            
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="inventoryMovementReportDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-btn
                            block
                            dark
                            color="green"
                            @click="downloadInventoryMovementReport()"
                            class="my-2"
                            max-width="100%"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            Download Excel
                        </v-btn>         
                        <v-data-table
                            :headers="inventoryMovementReportHeaders"
                            :items="inventoryMovementReportData"
                        ></v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- inventoryMovementReportDialog end -->
        <!-- imagesDialog start -->
        <v-dialog v-model="imagesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="imagesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="pictureHeaders"
                            :items="pictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.view_pictures`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.damage_pictures`]="{ item }">
                                <div v-if="item.damage_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.damage_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openDamagePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Damage Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.post_service_pictures`]="{ item }">
                                <div v-if="item.post_service_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.post_service_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openPostServicePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Post Service Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--imagesDialog end -->
        <!-- damagePicturesDialog start -->
        <v-dialog v-model="damagePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Damage Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="damagePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="damagePictureHeaders"
                            :items="damagePictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.download_damage_picture`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadDamagePicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.damage_pictures`]="{ item }">
                                <div v-if="item.damage_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.damage_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openDamagePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Damage Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePicturesDialog end -->
        <!-- postServicePicturesDialog start -->
        <v-dialog v-model="postServicePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Post Service Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="postServicePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="postServicePictureHeaders"
                            :items="postServicePictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.download_post_service_picture`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPostServicePicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePicturesDialog end -->
        <!-- location1SelectionFormDialog start -->
        <v-dialog v-model="location1SelectionFormDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Select Location1 -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="location1SelectionFormDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                            label="Container Location"
                            :items="containerLocations"
                            item-text="container_location"
                            item-value="container_location_id"
                            outlined
                            dense
                            v-model="container_location"
                        ></v-select>
                        
                        <v-btn
                            v-if="container_location"
                            color="green"
                            block
                            dark
                            @click="putTimestampInContainerLocatedAt()"
                        >
                            <v-icon>mdi-map-marker</v-icon>
                            Save Location1 & Put the Timestamp
                        </v-btn>                        
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
    <!-- location1SelectionFormDialog end -->
    <!-- location2SelectionForDialog start -->
    <v-dialog v-model="location2SelectionFormDialog" fullscreen>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            Select Location2 -- Container#: {{ currentContainerNumber }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="location2SelectionFormDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                                    outlined
                                    dense
                                    label="Container Location2"
                                    placeholder="Container Location2"
                                    v-model="containerLocation2"
                                    :items="containerLocations2"
                                ></v-select>
                                <v-btn
                                    v-if="containerLocation2"
                                    color="green"
                                    dark
                                    block
                                    @click="setLocation2()"
                                >
                                    <v-icon>mdi-map-marker</v-icon>
                                    Set Location2 & put the timestamp
                                </v-btn>                                
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- location2SelectionForDialog end -->        
    </v-container>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            tab: 0,
            jobOrderStatusTab: 0,
            search: null,
            overlay: false,
            loading: false,
            imagesDialog: false,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            timeRange: [0, 1440],
            fromTime: '00:00:00',
            untilTime: '24:00:00',            
            headers: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "CompanyName",
                    value: "company.name",
                    sortable: false,
                },
                {
                    text: "Remark",
                    value: "remark.remark_name",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "ContainerLocation",
                    value: "container_location.container_location",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt",
                    value: "container_located_at",
                    sortable: false,
                },                
                {
                    text: "ContainerLocation2",
                    value: "container_location2",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt2",
                    value: "container_located_at2",
                    sortable: false,
                },
                {
                    text: "ContainerLocation3",
                    value: "container_location3",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt3",
                    value: "container_located_at3",
                    sortable: false,
                },
                {
                    text: "ContainerPosition",
                    value: "container_position",
                    sortable: false,
                },           
                {
                    text: "SurveyStatus",
                    value: "survey.status_code",
                    sortable: false,
                },
                {
                    text: "JobOrderStatus",
                    value: "status_history[0].status.status_code",
                    sortable: false,
                },
                {
                    text: "Pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "CreatedBy",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],
            headersForNonAdmin: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "CompanyName",
                    value: "company.name",
                    sortable: false,
                },
                {
                    text: "Remark",
                    value: "remark.remark_name",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "RepairClassification",
                    value: "repair_classification.repair_classification",
                    sortable: false,
                },
                {
                    text: "SurveyStatus",
                    value: "survey.status_code",
                    sortable: false,
                },
                {
                    text: "JobOrderStatus",
                    value: "job_order_status",
                    sortable: false,
                },
                // {
                //     text: "JobOrderStatus",
                //     value: "status_history[0].status.status_code",
                //     sortable: false,
                // },
                {
                    text: "GateIn",
                    value: "gate_in",
                    sortable: false,
                },
                {
                    text: "ContainerLocation",
                    value: "container_location.container_location",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt",
                    value: "container_located_at",
                    sortable: false,
                },                
                {
                    text: "ContainerLocation2",
                    value: "container_location2",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt2",
                    value: "container_located_at2",
                    sortable: false,
                },
                {
                    text: "ContainerLocation3",
                    value: "container_location3",
                    sortable: false,
                },
                {
                    text: "ContainerLocatedAt3",
                    value: "container_located_at3",
                    sortable: false,
                },
                {
                    text: "ContainerPosition",
                    value: "container_position",
                    sortable: false,
                },
                {
                    text: "TruckDetails",
                    value: "truck_details",
                    sortable: false,
                },
                {
                    text: "SideLifterDetails",
                    value: "side_lifter_details",
                    sortable: false,
                },
                {
                    text: "Pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "CreatedBy",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],
            transactions: [],
            currentItem: null,
            currentContainerNumber: null,
            currentTransactionId: null,
            pictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "ViewPictures",
                    value: "view_pictures",
                    sortable: true,
                },
                {
                    text: "DamagePictures",
                    value: "damage_pictures",
                    sortable: true,
                },
                {
                    text: "PostServicePictures",
                    value: "post_service_pictures",
                    sortable: true,
                },
            ],
            pictures: [],
            statuses: [
                {
                    status_id: 'S-02',
                    status_code: 'SURVEYED',
                },
                {
                    status_id: 'S-05',
                    status_code: 'GATEIN',
                },
                {
                    status_id: 'S-06',
                    status_code: 'CONTAINERLOCATED',
                },
                {
                    status_id: 'S-07',
                    status_code: 'JOBSTARTED',
                },
                {
                    status_id: 'S-08',
                    status_code: 'JOBCLOSED',
                },
                {
                    status_id: 'S-09',
                    status_code: 'GATEOUT',
                },
            ],
            remarks: ['PASS', 'REJECTED', 'SURVEY ONLY'],
            damagePicturesDialog: false,
            damagePictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Download Damage Picture",
                    value: "download_damage_picture",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
            ],
            damagePictures: [],
            postServicePictures: [],
            postServicePicturesDialog: false,
            postServicePictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Download Post Service Picture",
                    value: "download_post_service_picture",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
            ],
            inventoryMovementReportDialog: false,
            inventoryMovementReportHeaders: [
                {
                    text: "Company",
                    value: "company_name",
                    sortable: true,
                },                
                {
                    text: "Booking",
                    value: "S-01",
                    sortable: true,
                },                
                {
                    text: "Survey",
                    value: "S-02",
                    sortable: true,
                }, 
                {
                    text: "Rejected",
                    value: "S-03",
                    sortable: true,
                },                                               
                {
                    text: "Survey Only",
                    value: "S-04",
                    sortable: true,
                },                                               
                {
                    text: "Gate In",
                    value: "S-05",
                    sortable: true,
                },                                               
                {
                    text: "Stackyard",
                    value: "S-06",
                    sortable: true,
                },                
                {
                    text: "Job Start",
                    value: "S-07",
                    sortable: true,
                },                
                {
                    text: "Job Close",
                    value: "S-08",
                    sortable: true,
                },                               
                {
                    text: "Gate Out",
                    value: "S-09",
                    sortable: true,
                },                
                          
            ],
            inventoryMovementReportData: [],
            location1SelectionFormDialog: false,
            containerLocations: [],
            container_location: null,
            location2SelectionFormDialog: false,
            containerLocations2: ["Stackyard", "ATI-HRD Pool", "HRD Withdrawal"],
            containerLocation2: null,
        }
    },
    mounted() {
        if( !this.$store.state.user.role.match(/^su$|admin|surveyor|ec|mnr|gater/) ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }
        if( this.$route.params.transaction_id && this.$route.params.tab ) {
            this.search = this.$route.params.transaction_id;
            this.tab = parseInt(this.$route.params.tab);
        } else {
            this.search = null;
            this.tab = 0;
        }

        if( this.$store.state.user.role !== 'admin' ) {
            this.headers = this.headersForNonAdmin;
        }

        this.loadTransactions(this.tab);
        // this.getStatuses();
        // this.getRemarks();
        if( !this.$store.state.routes.some(obj => obj.href == this.$route.path) ) {
            this.$store.commit(
                'addRoute',
                {
                    text: this.$route.path,
                    disabled: false,
                    href: this.$route.path,                
                }            
            );
        }
    },
    methods: {
        loadTransactions: function(tab) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_transactions',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    tab: tab,
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,
                    jobOrderStatusTab: this.jobOrderStatusTab,
                }
            ).then(response => {
                    console.log(response.data);
                    if(response.status === 200) {
                        this.transactions = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                    this.$store.commit('logOut');
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },       
        // getStatuses: function() {
        //     this.axios.post('/api/get_statuses')
        //         .then(response => {
        //             this.statuses = response.data;
        //             console.log(this.statuses)
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        gateIn: function(item) {

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/gate_in',
                {
                    job_order_id: item.job_order_id,
                    user_id: this.$store.state.user.id,
                    transaction_id: item.transaction_id
                }
            ).then(response => {
                this.loadTransactions(this.tab);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = true;
                this.loading = true;
            });
        },
        // getRemarks: function() {
        //     this.axios.post('/api/get_remarks')
        //         .then(response => {
        //             this.remarks = response.data;
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        openImagesDialog: function(item) {
            // console.log(item)
            this.imagesDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;
            this.currentTransactionId = this.currentItem.transaction_id;
            this.pictures = this.currentItem.pictures;
        },
        downloadPicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_picture",
            {
                container_number: item.container_number,
                transaction_id: item.transaction_id,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        downloadDamagePicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_damage_picture",
            {
                container_number: this.currentContainerNumber,
                transaction_id: this.currentTransactionId,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        downloadPostServicePicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_post_service_picture",
            {
                container_number: this.currentContainerNumber,
                transaction_id: this.currentTransactionId,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        openDamagePicturesDialog: function(item) {
            // console.log(item)
            this.damagePicturesDialog = true;
            this.damagePictures = item.damage_pictures;
            this.postServicePictures = item.post_service_pictures;
        },
        openPostServicePicturesDialog: function(item) {
            // console.log(item)
            this.postServicePicturesDialog = true;
            this.postServicePictures = item.post_service_pictures;
        },
        // jobOrderStatus: function(item) {
        //     if(item.job_order) {


        //         if(
        //             !item.job_order.gate_in_at
        //             &&
        //             !item.job_order.job_started_at
        //             &&
        //             !item.job_order.job_closed_at
        //             &&
        //             !item.job_order.gate_out_at
        //         ) {
        //             return '-';
        //         } else if(
        //             item.job_order.gate_in_at
        //             &&
        //             !item.job_order.job_started_at
        //             &&
        //             !item.job_order.job_closed_at
        //             &&
        //             !item.job_order.gate_out_at
        //         ) {
        //             return 'Gate In';
        //         } else if(
        //             item.job_order.gate_in_at
        //             &&
        //             item.job_order.job_started_at
        //             &&
        //             !item.job_order.job_closed_at
        //             &&
        //             !item.job_order.gate_out_at
        //         ) {
        //             return 'Job Started';
        //         } else if(
        //             item.job_order.gate_in_at
        //             &&
        //             item.job_order.job_started_at
        //             &&
        //             item.job_order.job_closed_at
        //             &&
        //             !item.job_order.gate_out_at
        //         ) {
        //             return 'Job Closed';
        //         } else if(
        //             item.job_order.gate_in_at
        //             &&
        //             item.job_order.job_started_at
        //             &&
        //             item.job_order.job_closed_at
        //             &&
        //             item.job_order.gate_out_at
        //         ) {
        //             return 'Gate Out';
        //         }
        //     } else {
        //         return '-';
        //     }
        // },
        changePage: function() {
            this.loadTransactions(this.tab);
        },
        searchByKeyword: function() {
            this.page = 1;
            this.loadTransactions(this.tab);
        },
        downloadInventoryMovementReport: function() {
            axios.post(
                '/api/download_inventory_movement_report_excel',
                {
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,
                    jobOrderStatusTab: this.jobOrderStatusTab,
                }
            ).then(response => {
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'InventoryMovementReport_' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            });
        },
        getInventoryMovementReportData: function() {
            axios.post(
                '/api/get_inventory_movement_report_data',
                {
                    dates: this.dates,
                    fromTime: this.fromTime,
                    untilTime: this.untilTime,
                    jobOrderStatusTab: this.jobOrderStatusTab,
                }
            ).then(response => {
                this.downloading = false;
                this.overlay = false;
                this.inventoryMovementReportData = response.data;
                // console.log(response.data)
            }).catch(error => {
                console.log(error);
            });
        },
        openLocation1SelectionFormDialog: function(item) {
            this.location1SelectionFormDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = item.container_number;
            this.getContainerLocations();
        },
        putTimestampInContainerLocatedAt: function() {
            this.loading = true;
            this.axios.post(
                '/api/put_timestamp_in_container_located_at',
                {
                    transaction_id: this.currentItem.transaction_id,
                    created_by: this.$store.state.user.id,
                    container_location: this.container_location,
                }            
            )
                .then(response => {
                    if(response.status == 200) {
                        this.location1SelectionFormDialog = false;
                        this.loadTransactions(this.tab);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },
        openInventoryMovementReportDialog: function() {
            this.inventoryMovementReportDialog = true;
            this.getInventoryMovementReportData();
        },
        getContainerLocations: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_container_locations'
            ).then(response => {
                this.containerLocations = response.data;
                // console.log(this.containerLocations);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        openLocation2SelectionFormDialog: function(item) {
            this.location2SelectionFormDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;            
        },
        setLocation2: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/set_location2',
                {
                    transaction_id: this.currentItem.transaction_id,
                    container_location2: this.containerLocation2
                }            
            )
                .then(response => {
                    if(response.status == 200) {
                        this.location2SelectionFormDialog = false;
                        this.loadTransactions(this.tab);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });    
        },
        formatTimeRange: function(times) {
            const fromHours = Math.floor(times[0] / 60);
            const fromMinutes = times[0] % 60;
            const untilHours = Math.floor(times[1] / 60);
            const untilMinutes = times[1] % 60;

            this.fromTime = String(fromHours).padStart(2, '0') + ':' + 
                            String(fromMinutes).padStart(2, '0') + ':00';
            this.untilTime = String(untilHours).padStart(2, '0') + ':' + 
                            String(untilMinutes).padStart(2, '0') + ':00';

            return this.fromTime + '~' + this.untilTime;      
        },   
        formatTime(value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
        } ,      
        jobOrderStatus: function(item) {
            // return item.status_history.filter(i => i.status_id == this.statuses[this.jobOrderStatusTab]?.status_id);
            return this.statuses[this.jobOrderStatusTab]?.status_code;
        }       
    },   
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
    watch: {
        tab: function() {
            this.loadTransactions(this.tab);
        },
        jobOrderStatusTab: function() {
            this.loadTransactions(this.tab);
        },
        dates: function() {
            if (this.dates.length == 0) {
                return false;
            } else if (this.dates.length == 1) {
            this.loadTransactions(this.tab);
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                } else {
                    this.page = 1;
                    this.timeRange = [0, 1440];
                    this.fromTime = '00:00:00';
                    this.untilTime = '24:00:00';
                    this.loadTransactions(this.tab);
                }
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },

}
</script>