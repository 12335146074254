<template>
    <v-container fluid>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card>
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Roles</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
        }
    },
    mounted() {

        if( this.$store.state.user.role !== 'su' ) {
        alert('You have no access right to this page...');
        this.$router.push('/login');
        }
    },    
}
</script>